import { Component, Vue, Model , Watch } from "vue-property-decorator";
import { phone, pas, phoneCode, confirmpas } from "@/utils/rule";
import backHead from "@/components/backHead";
import headbox from "@/components/head.vue";
import * as registerbox from "@/utils/api";

@Component({
  components: {
    backHead,
    headbox
  }
})
export default class RespawModel extends Vue {
  private FirstStep = true;
  private SecondStep = false;
  private than = this;
  // 确认按钮点击时机
  private confirm = false;
  // 判断是否从设置的修改密码跳转 是禁止输入手机号
  private disabled = false;
  /**
   * 定义当前数据
   */
  private RegisterForm = {
    // 手机账号 and 手机验证码
    phone: "",
    captcha: "",
    password: "",
    confirmpassword: ""
  };
  private successnext = false;
  // 倒计时初始化
  private phonetime = {
    // 第一步验证码不正确
    successnext: false,
    time: "获取验证码",
    btndisable: false
  };

  private lastResPasForm = {
    // 密码 and 确认密码
    password: "",
    confirmpassword: ""
  };

  /**
   * 定义当前表单验证
   */
  private loginRules = {
    phone: [{ required: true, trigger: "blur", validator: phone[0].validator }],
    captcha: [
      { required: true, trigger: "blur", validator: phoneCode[0].validator }
    ],
    password: [
      { required: true, trigger: "blur", validator: pas[0].validator }
    ],
    confirmpassword: [
      { required: true, trigger: "blur", validator: confirmpas[0].validator }
    ]
  };

  // 生命周期钩子
    mounted() {
      const type = this.$route.query.typePath
      if(type == 'loginOut'){
        this.disabled = true
        let phone = localStorage.getItem('moible') || ''
        if(!phone) {
          registerbox.getuser().then((res: any) => {
            this.RegisterForm.phone = res.phone
          })
        } else {
          this.RegisterForm.phone = phone
        }
      }
    }

  // 监听表单数据 
  @Watch('RegisterForm',{deep:true})
  onTypeChange(newVal:any, oldVal:any) {
    if (newVal.captcha && newVal.phone && newVal.password && newVal.confirmpassword) {
      (this.$refs.RegisterForm as any).validate((valid: boolean) => {
        if (valid) {
              this.confirm = true
        }else{
          this.confirm = false

        }
      });
    }
  }
  // 获取验证码
  getCode(formName:any) {
    (this.$refs[formName] as any).validateField("phone", (phoneError:string) => {
      //验证手机号码是否正确
      if (!phoneError) {
        // 手机号码正确
        if (!this.phonetime.btndisable) {
          this.phonetime.btndisable = true;
          let lastTime = 60;
          const timer = setInterval(() => {
            if (lastTime > 0) {
              this.phonetime.time = lastTime + "s";
              lastTime--;
            } else {
              window.clearInterval(timer);
              this.phonetime.btndisable = false;
              this.phonetime.time = "获取验证码";
            }
          }, 1000);
          registerbox
            .getPhoneCode(this.RegisterForm.phone, "FORGOT")
            .then((res:any) => {
              if (res.code != 0) {
                this.$toast.fail(res.msg);
                window.clearInterval(timer);
                this.phonetime.btndisable = false;
                this.phonetime.time = "获取验证码";
              } else {
                // 验证码正确，可以跳转下一步
                this.successnext = true;
              }
            })
            .catch(() => {
              window.clearInterval(timer);
              this.phonetime.btndisable = false;
              this.phonetime.time = "获取验证码";
            });
        }
      } else {
        return false;
      }
    });
  }

  // 确认按钮
  lastPost(formName:any) {
    
    (this.$refs[formName] as any).validate((valid: boolean) => {
      if (valid) {
        if (!this.successnext) {
          this.$toast.fail("验证码有误");
        }else{
        // this.RegisterForm.password = this.lastResPasForm.password;
        registerbox.respas(this.RegisterForm).then((res:any) => {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("moible");
          localStorage.removeItem("userinfo");
          localStorage.removeItem("address");
          
          this.$router.push({
            name: "successpage",
            query: { type: "chongzhimima" }
          });
        });
      }
      } else {
        return false;
      }
    });
  }
}
